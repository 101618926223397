.keyboardCont {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboardCont div {
  display: flex;
}

.secondRow {
  margin: 0.5rem 0;
}

.keyboardButton {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem;
  margin: 0 2px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  background-color: #1d1d1d;
  border: 1px solid #343434;
  border-radius: 5px;
}
