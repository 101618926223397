.gameBoard {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.letterBox {
  border: 2px solid #343434;
  border-radius: 3px;
  margin: 2px;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.filledBox {
  border: 2px solid black;
}

.letterRow {
  display: flex;
}
